.language-selector {
    display: flex;

    label {
        // display: none;
        padding: 0.5rem 1rem;
        border: 2px solid var(--secondary-text-colour);
        margin-right: 0.5rem;

        input[type="radio"] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }

        &.active,
        &:hover {
            background: var(--app-purple);
        }
    }
}

.gaeilge-apology {
    margin-top: 1rem;
    &.show {
        display: block;
    }
    &.hide {
        display: none;
    }
}
