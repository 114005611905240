.navbar-link {
    display: grid;
    justify-content: flex-end;

    a {
        border-bottom: 3px solid var(--main-text-colour);
        color: var(--main-text-colour);
        margin-right: 0.5rem;
        padding: 0.5rem;
        text-decoration: none;

        &.line {
            font-weight: 700;
        }

        &:hover, &:focus {
            background: var(--hover-colour);
        }

        :last-child {
            margin-right: 0;
        }
    }
}
