.navbar {
    position: fixed;
    bottom: 0;
    width: 100%;

    display: flex;
    justify-content: center;

    background: var(--background);

    nav {
        border-top: 2px solid var(--secondary-text-colour);

        display: flex;
        height: 4rem;
        max-width: 800px;

        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 0.25rem 0.5rem;

        width: 100%;

        div {
            h1 {
                font-size: 0.7rem;
                margin: 0;
                text-transform: uppercase;
            }

            &.line-section {
                margin-right: 0.5rem;
            }

            .line-section-links {
                display: flex;
                white-space: nowrap;

                p {
                    margin: 0;
                    padding: 0.5rem;
                }
            }
        }
    }
}
