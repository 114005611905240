@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
:root{--main-text-colour: #212121;--secondary-text-colour: #c9c9c9;--red: #f44336;--green: #00af00;--background: #fff;--hover-colour: #f5f5f5;--favourite-yellow: #fdd835;--link-color: #14599F;--app-purple: #ecd7f0}@media (prefers-color-scheme: dark){:root{--main-text-colour: #fff;--secondary-text-colour: #565656;--red: #f44336;--green: #00af00;--background: #212121;--hover-colour: #383838;--favourite-yellow: #fdd835;--link-color: #77B3EE;--app-purple: #9f42ac}}html,body{height:100%;width:100%}body{background:var(--background);color:var(--main-text-colour);font-family:"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;margin:0}a,a:visited{color:var(--link-color)}

.app{padding-bottom:1px}.app .main-content{max-width:800px;margin:0 auto 4.5rem auto}button{background:var(--hover-colour);border:solid 1px var(--main-text-colour);margin-right:0.5rem;padding:0.5rem 1rem}

.forecast main{padding:0.5rem}.forecast main .section{margin:3rem 0}.forecast main .walking-transfer{display:inline-flex}.forecast main .updating{font-size:0.8rem;font-style:italic;font-weight:400;text-align:right;margin:0}.forecast main .facility{display:flex}.forecast main .facility svg{height:2rem;width:2rem;margin-right:1rem}.forecast main .facility span{margin:auto 0}

.direction-forecast .direction{align-items:center;display:flex}.direction-forecast .direction h2,.direction-forecast .direction h3{margin-bottom:0}.direction-forecast .direction .direction-last-tram{max-width:40%;text-align:right;font-size:0.8rem;font-style:italic;margin-left:auto}.direction-forecast ul{padding:0}.direction-forecast ul li{list-style-type:none}

.direction-forcast-item{border-bottom:1px solid var(--secondary-text-colour);display:flex;list-style-type:none;padding:0.5rem 0}.direction-forcast-item:last-child{border:0}.direction-forcast-item .left{margin-right:1rem}.direction-forcast-item .right{margin-left:auto}

.operating-hours-day-row{border-bottom:1px solid var(--secondary-text-colour)}.operating-hours-day-row:first-of-type{border-top:1px solid var(--secondary-text-colour)}.operating-hours-day-row .op-hours-header{align-items:center;display:flex;margin:0;padding:1rem 0}.operating-hours-day-row .op-hours-header h4{margin:0}.operating-hours-day-row .op-hours-header svg{margin-left:auto}.operating-hours-day-row .op-hours-header.open{border-bottom:2px solid var(--secondary-text-colour)}.operating-hours-day-row ul{background:var(--hover-colour);margin:0;padding:1rem}

.page-header{border-bottom:0.75rem solid var(--main-text-colour);display:flex;margin:0;padding:0.5rem}.page-header div{margin:auto}.page-header div.title{flex-grow:5}.page-header h1{margin:0;font-size:1.8rem}.page-header h1 span{font-style:italic;font-weight:400}.back-arrow svg{color:var(--main-text-colour);margin-right:0.5rem;width:2rem}

button.favourite-star{background:none;border:none;margin-left:auto;padding:0}button.favourite-star svg{color:var(--secondary-text-colour);width:2rem}button.favourite-star:hover svg,button.favourite-star:focus svg{color:var(--favourite-yellow)}@media screen and (-ms-high-contrast: black-on-white){button.favourite-star svg{color:black}}@media screen and (-ms-high-contrast: white-on-black){button.favourite-star svg{color:white}}

.navbar-link{display:grid;justify-content:flex-end}.navbar-link a{border-bottom:3px solid var(--main-text-colour);color:var(--main-text-colour);margin-right:0.5rem;padding:0.5rem;text-decoration:none}.navbar-link a.line{font-weight:700}.navbar-link a:hover,.navbar-link a:focus{background:var(--hover-colour)}.navbar-link a :last-child{margin-right:0}

.station-list header{display:flex}.station-list header a{align-self:center;color:var(--main-text-colour);margin-left:auto}.station-list .colour-nav{font-size:2rem}.station-list .colour-nav a{color:var(--main-text-colour);font-weight:700;margin-right:1rem}.station-list .colour-nav a.active-line{color:var(--secondary-text-colour);text-decoration:none}.station-list .colour-nav a:hover,.station-list .colour-nav a:active{text-decoration:underline}.station-list .list ul{margin-top:0;padding:0}

.station-list-row{border-bottom:1px solid var(--secondary-text-colour);border-left:0.5rem solid var(--main-text-colour);display:flex;list-style-type:none}.station-list-row:hover,.station-list-row:focus{background:var(--hover-colour)}.station-list-row a{color:var(--main-text-colour);font-weight:700;height:100%;margin-right:1rem;padding:0.8rem 0.5rem;text-decoration:none;width:100%}.station-list-row a span{font-style:italic;font-weight:400}

.navbar{position:fixed;bottom:0;width:100%;display:flex;justify-content:center;background:var(--background)}.navbar nav{border-top:2px solid var(--secondary-text-colour);display:flex;height:4rem;max-width:800px;overflow-x:auto;overflow-y:hidden;white-space:nowrap;padding:0.25rem 0.5rem;width:100%}.navbar nav div h1{font-size:0.7rem;margin:0;text-transform:uppercase}.navbar nav div.line-section{margin-right:0.5rem}.navbar nav div .line-section-links{display:flex;white-space:nowrap}.navbar nav div .line-section-links p{margin:0;padding:0.5rem}

.language-selector{display:flex}.language-selector label{padding:0.5rem 1rem;border:2px solid var(--secondary-text-colour);margin-right:0.5rem}.language-selector label input[type="radio"]{position:absolute;clip:rect(0, 0, 0, 0);pointer-events:none}.language-selector label.active,.language-selector label:hover{background:var(--app-purple)}.gaeilge-apology{margin-top:1rem}.gaeilge-apology.show{display:block}.gaeilge-apology.hide{display:none}

