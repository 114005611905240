.app {
    // iPhone Safari won't respect margin-bottom unless there is something to push against
    padding-bottom: 1px;

    .main-content {
        max-width: 800px;
        margin: 0 auto 4.5rem auto;
    }
}

button {
    background: var(--hover-colour);
    border: solid 1px var(--main-text-colour);
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
}
