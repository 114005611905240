.station-list {
    header {
        display: flex;

        a {
            align-self: center;

            color: var(--main-text-colour);
            margin-left: auto;
        }
    }

    .colour-nav {
        font-size: 2rem;

        a {
            color: var(--main-text-colour);
            font-weight: 700;
            margin-right: 1rem;

            &.active-line {
                color: var(--secondary-text-colour);
                text-decoration: none;
            }

            &:hover,
            &:active {
                text-decoration: underline;
            }
        }
    }

    .list {
        ul {
            margin-top: 0;
            padding: 0;
        }
    }
}
