button.favourite-star {
    background: none;
    border: none;
    margin-left: auto;
    padding: 0;

    svg {
        color: var(--secondary-text-colour);
        width: 2rem;
    }

    &:hover svg,
    &:focus svg {
        color: var(--favourite-yellow);
    }

    @media screen and (-ms-high-contrast: black-on-white) {
        svg {
            color: black;
        }
    }

    @media screen and (-ms-high-contrast: white-on-black) {
        svg {
            color: white;
        }
    }
}
