.operating-hours-day-row {
    border-bottom: 1px solid var(--secondary-text-colour);

    &:first-of-type {
        border-top: 1px solid var(--secondary-text-colour);
    }

    .op-hours-header {
        align-items: center;
        display: flex;
        margin: 0;
        padding: 1rem 0;

        h4 {
            margin: 0;
        }

        svg {
            margin-left: auto;
        }

        &.open {
            border-bottom: 2px solid var(--secondary-text-colour);
        }
    }

    ul {
        background: var(--hover-colour);
        margin: 0;
        padding: 1rem;
    }
}
