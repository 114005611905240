.station-list-row {
   border-bottom: 1px solid var(--secondary-text-colour);
   border-left: 0.5rem solid var(--main-text-colour);
   display: flex;
   list-style-type: none;

   &:hover,
   &:focus {
      background: var(--hover-colour);
   }

   a {
      color: var(--main-text-colour);
      font-weight: 700;
      height: 100%;
      margin-right: 1rem;
      padding: 0.8rem 0.5rem;
      text-decoration: none;
      width: 100%;

      span {
         font-style: italic;
         font-weight: 400;
      }
   }
}
