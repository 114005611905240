@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

:root {
   --main-text-colour: #212121;
   --secondary-text-colour: #c9c9c9;
   --red: #f44336;
   --green: #00af00;
   --background: #fff;
   --hover-colour: #f5f5f5;
   --favourite-yellow: #fdd835;
   --link-color: #14599F;
   --app-purple: #ecd7f0;

   @media (prefers-color-scheme: dark) {
      --main-text-colour: #fff;
      --secondary-text-colour: #565656;
      --red: #f44336;
      --green: #00af00;
      --background: #212121;
      --hover-colour: #383838;
      --favourite-yellow: #fdd835;
      --link-color: #77B3EE;
      --app-purple: #9f42ac;
   }
}

html,
body {
   height: 100%;
   width: 100%;
}

body {
   background: var(--background);
   color: var(--main-text-colour);
   font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   margin: 0;
}

a, a:visited {
   color: var(--link-color);
}